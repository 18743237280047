<script lang="ts" setup>
import { computed } from "vue";
import { mdiAccountGroup, mdiClipboardTextMultipleOutline, mdiScriptTextOutline } from "@mdi/js";

const breadcrumbs = computed(() => [{ default: "IAM-BaseOverview" }]);
</script>

<template>
  <v-container fluid pa-0 class="app-iam">
    <inn-breadcrumbs :items="breadcrumbs"></inn-breadcrumbs>

    <v-container fluid>
      <header class="app-welcome-message">
        <h2><v-icon>mdi-account-multiple</v-icon> Identiteits- en toegangsbeheer</h2>
      </header>
      <v-row>
        <v-col cols="2" class="sidebar">
          <v-list nav>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-UsersOverview' }">
                <v-list-item-icon><v-icon>mdi-account-multiple</v-icon></v-list-item-icon>
                <v-list-item-title>Gebruikers</v-list-item-title>
              </v-list-item>
              <v-list-item text :to="{ name: 'IAM-UserGroupsOverview' }">
                <v-list-item-icon
                  ><v-icon>{{ mdiAccountGroup }}</v-icon></v-list-item-icon
                >
                <v-list-item-title>Groepen</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-divider></v-divider>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-ResourcesOverview' }">
                <v-list-item-icon><v-icon>mdi-apps</v-icon></v-list-item-icon>
                <v-list-item-title>Resources</v-list-item-title>
              </v-list-item>
              <v-list-item text :to="{ name: 'IAM-ProjectsOverview' }">
                <v-list-item-icon
                  ><v-icon>{{ mdiClipboardTextMultipleOutline }} </v-icon></v-list-item-icon
                >
                <v-list-item-title>Projecten</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-divider></v-divider>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-RolesOverview' }">
                <v-list-item-icon
                  ><v-icon>{{ mdiScriptTextOutline }}</v-icon></v-list-item-icon
                >
                <v-list-item-title>Rollen</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col>
          <v-layout>
            <v-img src="@/assets/img/rolesandrights.png" class="image" width="100%" />
          </v-layout>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
